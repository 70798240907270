<template lang="pug">
    #redes
        //- a(href="#", target="blank", title="Facebook")
        //-     img(src="@images/icones/icon-facebook.png", alt="Facebook")
        //- a(href="#", target="blank", title="Twitter")
        //-     img(src="@images/icones/icon-twitter.png", alt="Twitter")
        a(href="https://instagram.com/euroimportlandrover", target="blank", title="Instagram")
            img(src="@images/icones/icon-instagram.png", alt="Instagram")
</template>

<script>
export default {
    name: "component-redes",
}
</script>

<style lang="stylus" scoped src="./Redes.styl"></style>